// import Logo from "public/favicon-32x32.svg";
// import colors from "tailwindcss/colors";

export const HEADER = {
  logo: {
    href: "/",
    title: <span className="text-purple-300 text-1l  tracking-wide text-shadow-md">
    &lt;Jeevesh Krishna/&gt;
  </span>,
    alt: "Logo",
  },
  nav: [
    {
      href: "/",
      title: "Home",
      alt: "Country roads..",
      desktop: false,
    },
    {
      href: "/#about",
      title: "About",
      alt: "More about me.",
      desktop: true,
    },
    {
      href: "/#portfolio",
      title: "Portfolio",
      alt: "Work I've done.",
      desktop: true,
    },
    {
      href: "https://blog.jeeveshkrishna.com",
      title: "Blog", 
      alt: "Where I write stuff",
      desktop: true,
    } /*
    {
      href: "/gallery",
      title: "Gallery",
      alt: "Images and stuff",
      desktop: true,
    },*/,
    {
      href: "/resume",
      title: "Resume",
      alt: "My professional resume",
      desktop: true,
    },
  ],
  profileNav: [],
};
