export const SEO = {
  title: "Jeevesh Krishna - Fullstack Developer",
  url: "https://jeeveshkrishna.com",
  description:
    "I'm a passionate Software Developer based in Massachusetts, currently pursuing my Master's in Computer Science at UMass Amherst. With a diverse background spanning web development, system administration, machine learning, cybersecurity, and cloud computing, I specialize in pushing technological boundaries. I'm currently focused on integrating modern cryptographic techniques such as homomorphic encryption into machine learning, aiming to build secure-by-design, zero-trust systems. My journey is driven by a lifelong love for technology and a relentless curiosity for solving complex challenges.",
  siteName: "jeeveshkrishna.com",
  github: "https://github.com/jeevesh2002",
  avatar: "https://raw.githubusercontent.com/jeevesh2002/assets/master/prof-cropped.jpeg",
  author: "Jeevesh Krishna",
  twitter: {
    handle: "@jeevesh_",
    site: "@jeevesh",
    cardType: "summary_large_image",
  },
};